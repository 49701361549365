@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;700&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: rgb(45, 45, 45);
}

body {
  height: 100vh;
  overflow: auto
}

html {
  display: block;
  position: relative;
  height: 100vh;
  overflow: auto
}

html::after {
  content: "";
  background: url(/static/media/bg-two.3313484e.jpg);
  background-position: center center;
  background-attachment: fixed;
  background-repeat: repeat-y;
  background-size: cover;
  opacity: 0.07;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  overflow-y: scroll;
}

.App {
  display: flex;
  justify-content: center;
}

#main-display {
  font-family: 'Raleway', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  width: 100%;
  max-width: 1500px;
  height: 100vh;
  margin: 2.5rem 7rem 0rem 7rem;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.main-nav {
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
  align-self: flex-end;
}

.main-nav-link {
  margin-left: 2rem;
  position: relative;
  text-decoration: none;
  color: rgb(45, 45, 45);
  font-weight: bold;
  font-size: 1.5rem;
}

.main-nav-link:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: -25%;
  left: 0;
  background-color: rgb(45, 45, 45);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.main-nav-link:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.main-nav-link:visited {
  text-decoration: none;
}

#hi {
  align-self: center;
  margin-top: 2.5rem;
  font-weight: 900;
  font-size: 4.5rem;
  width: 100%;
  max-width: 1500px;
}

#blurb-and-image {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1500px;

  -webkit-flex: 0;
  flex-shrink: 0;
}

#blurb {
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin-top: 2rem;
  margin-right: 2rem;
  max-width: 1100px;
}

#image-cropper {
  width: 250px;
  height: 250px;
  min-width: 250px;
  min-height: 250px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  border: 4px solid rgb(45, 45, 45);
}

#profile-picture {
  display: inline;
  margin: 0 auto;
  margin-left: -27%;
  height: 100%;
  width: auto;
}

#contact-nav {
  margin-top: 3rem;
  display: flex;
  width: 100%;
  justify-content: flex-start;

  -webkit-flex: 0;
  flex-shrink: 0;
}

.contact-icon {
  width: 2rem;
  margin-right: 2rem;
  transition: all .2s ease-in-out;
}

.contact-icon:hover {
  transform: scale(1.2);
}

#content-header {
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: 900;
  font-size: 4.5rem;
  width: 100%;
  max-width: 1500px;
}

#content-description {
  margin-bottom: 1.5rem;
  font-size: 1.3rem;
  line-height: 1.5rem;
  width: 650px;
}

#project-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 2rem;

  align-items: flex-start;
}

.project-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 380px;
  margin-top: -1rem;
  margin-bottom: 4rem;
  padding-right: 2rem;
}

.project-card-image {
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: solid 1px rgb(45, 45, 45);
}

.project-card-title {
  display: inline-block;
  font-weight: 700;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.9);
  border: none;
  text-decoration: none;
  margin-top: 1rem;
}

.project-card-description {
  color: rgba(0, 0, 0, 0.5);
  margin-top: 5px;
  margin-bottom: 1rem;
  line-height: 1.3em;
}

.project-links {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;
}

.project-icon {
  width: 30px;
  margin-right: 1rem;
  transition: all .2s ease-in-out;
}

.project-icon:hover {
  transform: scale(1.2);
}

.medium-widget-article__row {
  flex-direction: row !important;
}

.medium-widget-article__item {
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 33%;
}


@media screen and (max-width: 795px) {

  #hamburger {
    display: block;
    align-self: flex-end;
    cursor: pointer;
    margin-top: -1rem;
    margin-right: 1rem;
    z-index: 2;
  }

  .line {
    width: 35px;
    height: 6px;
    background: rgb(45, 45, 45);
    margin: 5px;
  }

  .line.open {
    background: whitesmoke;
  }

  .main-nav {
    display: flex;
    justify-content: space-evenly;
    align-self: unset;
    position: fixed;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    background: rgb(45, 45, 45);
    z-index: 1;
    top: 0px;
    pointer-events: none;
    clip-path: circle(100px at 90% -20%);
    -webkit-clip-path: circle(100px at 90% -20%);
    transition: all 1s ease-out;
  }

  .main-nav.open {
    clip-path: circle(1500px at 90% -10%);
    -webkit-clip-path: circle(1500px at 90% -10%);
    pointer-events: all;
  }

  .main-nav-link {
    margin-left: 0rem;
    position: relative;
    text-decoration: none;
    color: whitesmoke;
    font-weight: bold;
    font-size: 1.5rem;
  }

  .main-nav-link:before {
    content: "";
    position: absolute;
    width: 20%;
    height: 4px;
    bottom: -25%;
    left: 40%;
    background-color: whitesmoke;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
  }

  .main-nav-link:hover:before {
    visibility: visible;
    transform: scaleX(1);
  }

  #blurb-and-image {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    justify-content: space-between;
    width: 100%;
  }

  #hi {
    margin-bottom: 2rem;
    text-align: center;
    min-width: 370px;
    font-size: 4rem;
  }

  #blurb {
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-top: 2rem;
    margin-right: 0;
    max-width: 1100px;
    width: 100%;
    padding: 0 1rem;
  }

  #contact-nav {
    margin-top: 3rem;
    display: flex;
    width: 100%;
    max-height: 100px;
    justify-content: space-evenly;
    margin-bottom: 2rem;
  }

  a {
    display: flex;
    justify-content: center;
  }

  .contact-icon {
    width: 2rem;
    margin-right: 0rem;
  }

  #content-header {
    justify-self: center;
    align-self: center;
    text-align: center;

    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
    font-weight: 900;
    font-size: 4.5rem;
    width: 100%;
    max-width: 450px;
  }

  #content-description {
    margin-bottom: 1.5rem;
    font-size: 1.3rem;
    line-height: 1.5rem;
    max-width: 300px;
    text-align: center;
    align-self: center;
  }

  #project-card-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    flex: 0 0 auto;
  }

  .project-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 380px;
    margin-top: -1rem;
    margin-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;

    flex: 0 0 auto;
  }

  .project-card-image {
    width: 100%;
    max-height: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: solid 1px rgb(45, 45, 45);

    flex: 1 0 auto;
  }

  .project-card-title {
    display: inline-block;
    font-weight: 700;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.9);
    border: none;
    text-decoration: none;
    margin-top: 1rem;

    flex: 0 0 auto;
  }

  .project-card-description {
    color: rgba(0, 0, 0, 0.5);
    margin-top: 5px;
    margin-bottom: 1rem;
    line-height: 1.3em;
  }

  .project-links {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-start;

    align-items: center;
    max-height: 40px;
  }

  .project-icon {
    width: 30px;
    margin-right: 1rem;
    transition: all .2s ease-in-out;

    flex: 0 0 auto;
  }

  .medium-widget-article__row {
    flex-direction: column !important;
  }

  .medium-widget-article__item {
    min-width: 100%;
  }
}
#goodreads-widget {
    font-family: Arial, Helvetica, sans-serif;
}

#goodreads-widget a {
    text-decoration: none;
    color: black
}

.gr_custom_container_1588112847 {
    /* customize widget container */
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    color: #000000;
    max-width: 1300px;
}

.gr_custom_header_1588112847 {
    /* customize Goodreads header*/
    display: none;
}

.gr_custom_each_container_1588112847 {
    /* customize individual book container */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 220px;
    margin-bottom: 10px;
    overflow: auto;
    text-align: center;
}

.gr_custom_book_container_1588112847 {
    /* customize book covers */
    box-shadow: 1px 4px 12px -2px rgba(0, 0, 0, 0.75);
}

.gr_custom_title_1588112847 {
    /* customize titles */
    margin-top: .7rem;
    font-size: 1.2rem;
}

.gr_custom_author_1588112847 {
    /* customize author names */
    margin-top: .3rem;
    font-size: .8rem;
}

.gr_custom_rating_1588112847 {
    /* customize rating stars */
    margin-top: .25rem;
    order: 1;
}


.gr_custom_review_1588112847 {
    display: none;
}

img {
    vertical-align: bottom;
}

center {
    display: none;
}

@media (max-width: 795px) {

    .gr_custom_container_1588112847 {
        /* customize widget container */
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        color: #000000;
    }

    .gr_custom_each_container_1588112847 {
        /* customize individual book container */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 220px;
        margin-bottom: 2rem;
        overflow: auto;
        text-align: center;
    }

}
#medium-widget {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.article-card {
  width: 350px;
  padding: 1rem 2rem 1rem 0;
  margin-bottom: 1rem;
}

.article-title {
  margin: 1rem 0;
  text-decoration: none;
}

.article-title a {
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  display: inline !important;
}

@media only screen and (max-width: 500px) {
  .article-card {
    padding: 1rem;
  }
}

