#goodreads-widget {
    font-family: Arial, Helvetica, sans-serif;
}

#goodreads-widget a {
    text-decoration: none;
    color: black
}

.gr_custom_container_1588112847 {
    /* customize widget container */
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    color: #000000;
    max-width: 1300px;
}

.gr_custom_header_1588112847 {
    /* customize Goodreads header*/
    display: none;
}

.gr_custom_each_container_1588112847 {
    /* customize individual book container */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 220px;
    margin-bottom: 10px;
    overflow: auto;
    text-align: center;
}

.gr_custom_book_container_1588112847 {
    /* customize book covers */
    box-shadow: 1px 4px 12px -2px rgba(0, 0, 0, 0.75);
}

.gr_custom_title_1588112847 {
    /* customize titles */
    margin-top: .7rem;
    font-size: 1.2rem;
}

.gr_custom_author_1588112847 {
    /* customize author names */
    margin-top: .3rem;
    font-size: .8rem;
}

.gr_custom_rating_1588112847 {
    /* customize rating stars */
    margin-top: .25rem;
    order: 1;
}


.gr_custom_review_1588112847 {
    display: none;
}

img {
    vertical-align: bottom;
}

center {
    display: none;
}

@media (max-width: 795px) {

    .gr_custom_container_1588112847 {
        /* customize widget container */
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        color: #000000;
    }

    .gr_custom_each_container_1588112847 {
        /* customize individual book container */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 220px;
        margin-bottom: 2rem;
        overflow: auto;
        text-align: center;
    }

}